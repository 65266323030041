import {IonAvatar, IonBadge, IonButton, IonCol, IonIcon, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./PostReplyListItem.module.scss";
import {bookmark, bookmarkOutline, heart, heartOutline, star} from "ionicons/icons";
import {ActionLink} from "./ActionLink";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveCommentFavor} from "../store/Pg_3_Store";
import styles_Common from "../pages/Common.module.scss";
import {useStoreState} from "pullstate";
import {UserCouponBookmarkStore, UserPostBookmarkStore, UserPostCommentFavorStore, UserPostFavorStore} from "../store/Common_Store";
import {getPageData} from "../store/Selectors";
import Ln_PostTime from "../pages/Ln_PostTime";

const _push_pg_3_11__AddCommentFavor = async (commentId) => {
    if (await push_pg_3_11__AddCommentFavor({
        p1: commentId,
    })) {
        //navToPg_1_1();
    }
}
const _push_pg_3_11__RemoveCommentFavor = async (commentId) => {
    if (await push_pg_3_11__RemoveCommentFavor({
        p1: commentId,
    })) {
        //navToPg_1_1();
    }
}


export const PostReplyListItem = ({Item_留言, fromHome = false, noLink = false}) => {
    const router = useIonRouter();

    const postCommentFavors = useStoreState(UserPostCommentFavorStore, getPageData);


    let Item_會員公開: any = Item_留言?._會員公開;
    if (!Item_會員公開) {
        return <>(ERROR:Item_會員公開)</>;
    }

    return (
        <ActionLink link={`/tabs/5_3/${Item_留言.留言Id}`} className={styles.actionlink} noLink={noLink}>
            <div className={styles.container}>
                <div className="ll">
                    <IonAvatar className="avatar">
                        {Ln_TokenPictureSetCoverImg({
                            圖片組: Item_會員公開.個人照片,
                            名稱: 'cover',
                        })}
                    </IonAvatar>
                </div>
                <div className="l">
                    {Item_留言.內容}
                    <div className="dt">
                        <Ln_PostTime
                            msTimestamp={Item_留言.CreatedAt}/>
                    </div>
                </div>
                <div className="r">
                    {postCommentFavors[Item_留言.留言Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__RemoveCommentFavor(Item_留言.留言Id);

                                return false;
                            }}><IonIcon icon={heart} className={styles_Common.actionButtonIcon}></IonIcon>
                                {Item_留言.讚數}
                            </IonButton>
                        </>
                    }
                    {!postCommentFavors[Item_留言.留言Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__AddCommentFavor(Item_留言.留言Id);

                                return false;
                            }}><IonIcon icon={heartOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                                {Item_留言.讚數}
                            </IonButton>
                        </>
                    }
                </div>
            </div>
        </ActionLink>
    );
}
