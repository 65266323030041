import {IonAvatar, IonBadge, IonButton, IonChip, IonCol, IonIcon, IonLabel, IonNote, IonRouterLink, useIonRouter} from "@ionic/react";
import styles from "./PostItem.module.scss";
import styles_Common from "../pages/Common.module.scss";
import {bookmark, bookmarkOutline, chatboxEllipses, chatboxEllipsesOutline, heart, heartOutline, star} from "ionicons/icons";
import Ln_TokenPictureSetCoverImg from "../pages/Ln_TokenPictureSetCoverImg";
import {ActionLink} from "./ActionLink";
import Ln_ContractPictureSetCoverImg from "../pages/Ln_ContractPictureSetCoverImg";
import {capitalize} from "../utils";
import {push_pg_3_11__AddArticleBookmark, push_pg_3_11__AddArticleFavor, push_pg_3_11__AddCommentFavor, push_pg_3_11__RemoveArticleBookmark, push_pg_3_11__RemoveArticleFavor} from "../store/Pg_3_Store";
import {useStoreState} from "pullstate";
import {Pg_1_1_Store} from "../store/Pg_1_Store";
import {getPageData} from "../store/Selectors";
import {UserPostBookmarkStore, UserPostFavorStore} from "../store/Common_Store";
import Ln_PostTime from "../pages/Ln_PostTime";


//#region +Event
// const navToPg_1_1 = () => {
//     router.push("/tabs/1_1", "forward", "push");
// }

//#region push register
const _push_pg_3_11__AddCommentFavor = async (commentId) => {
    debugger;

    if (await push_pg_3_11__AddCommentFavor({
        p1: commentId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_3_11__AddArticleFavor = async (postId) => {
    if (await push_pg_3_11__AddArticleFavor({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_3_11__RemoveArticleFavor = async (postId) => {
    if (await push_pg_3_11__RemoveArticleFavor({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_3_11__AddArticleBookmark = async (postId) => {
    if (await push_pg_3_11__AddArticleBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

const _push_pg_3_11__RemoveArticleBookmark = async (postId) => {
    if (await push_pg_3_11__RemoveArticleBookmark({
        p1: postId,
    })) {
        //navToPg_1_1();
    }
}

//#endregion

//#endregion

export const PostItem = ({Item_文章, fromHome = false, noLink = false}) => {
    const router = useIonRouter();
    const postFavors = useStoreState(UserPostFavorStore, getPageData);
    const postBookmarks = useStoreState(UserPostBookmarkStore, getPageData);

    let Item_Token: any = Item_文章?._Token;
    if (!Item_Token) {
        return <>(ERROR:Item_Token)</>;
    }
    let Item_Token_合約: any = Item_Token?._合約;
    if (!Item_Token_合約) {
        return <>(ERROR:Item_Token_合約)</>;
    }
    let Item_Token_會員公開: any = Item_文章?._會員公開;
    if (!Item_Token_會員公開) {
        return <>(ERROR:Item_Token_會員公開)</>;
    }

    const navToPg_3_11_ByUser = (userId) => {
        router.push(`/tabs/3_1?userId=${userId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    const navToPg_3_11_ByContract = (contractId) => {
        router.push(`/tabs/3_1?contractId=${contractId}`, "forward", "push");

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }


    return (
        <ActionLink link={`/tabs/3_11/${Item_文章.文章Id}`} className={styles.container} noLink={noLink}>
            <div className={styles.containerInner}>
                <div className={styles.nameBar}>
                    <div className="container">
                        <div className="l" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            navToPg_3_11_ByUser(Item_Token_會員公開.會員Id);

                            return false;
                        }}>
                            <IonAvatar className="avatar">
                                {Ln_TokenPictureSetCoverImg({
                                    圖片組: Item_Token_會員公開.個人照片,
                                    名稱: 'cover',
                                })}

                            </IonAvatar>
                        </div>
                        <div className="r" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            navToPg_3_11_ByUser(Item_Token_會員公開.會員Id);

                            return false;
                        }}>
                            {Item_Token_會員公開.暱稱}
                        </div>
                        <div className="r2" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            navToPg_3_11_ByContract(Item_Token_合約.合約Id);

                            return false;
                        }}>
                            <div className="roundbg">
                                {Item_Token_合約.Name}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.postBar}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: Item_文章.封面組,
                        名稱: 'cover',
                    })}
                </div>
                <div className={styles.textBar}>
                    {Item_文章.內容}
                    <div className="dt">
                        <Ln_PostTime
                            msTimestamp={Item_文章.CreatedAt}/>
                    </div>
                </div>
                <div className={styles.actionBar}>
                    {/*///like*/}
                    {postFavors[Item_文章.文章Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__RemoveArticleFavor(Item_文章.文章Id);

                                return false;
                            }}><IonIcon icon={heart} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }
                    {!postFavors[Item_文章.文章Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__AddArticleFavor(Item_文章.文章Id);

                                return false;
                            }}><IonIcon icon={heartOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }
                    {Item_文章.讚數}

                    {/*///chatbox*/}
                    {Item_文章.回覆數 > 0 &&
                        <IonButton fill="clear" className={styles_Common.actionButton}>
                            <IonIcon icon={chatboxEllipses} className={styles_Common.actionButtonIcon}></IonIcon>
                        </IonButton>
                    }
                    {Item_文章.回覆數 === 0 &&
                        <IonButton fill="clear" className={styles_Common.actionButton}>
                            <IonIcon icon={chatboxEllipsesOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                        </IonButton>
                    }
                    {Item_文章.回覆數}

                    {postBookmarks[Item_文章.文章Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__RemoveArticleBookmark(Item_文章.文章Id);

                                return false;
                            }}><IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }
                    {!postBookmarks[Item_文章.文章Id] &&
                        <>
                            <IonButton fill="clear" className={styles_Common.actionButton} onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                _push_pg_3_11__AddArticleBookmark(Item_文章.文章Id);

                                return false;
                            }}><IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>
                            </IonButton>
                        </>
                    }

                    {/*///bookmark*/}
                    {/*<IonButton fill="clear" className={styles_Common.actionButton}>*/}
                    {/*    <IonIcon icon={bookmarkOutline} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                    {/*</IonButton>*/}
                    {/*<IonButton fill="clear" className={styles_Common.actionButton}>*/}
                    {/*    <IonIcon icon={bookmark} className={styles_Common.actionButtonIcon}></IonIcon>*/}
                    {/*</IonButton>*/}

                </div>
                {/*<div><p>{capitalize(item.Name)}</p></div>*/}
            </div>
        </ActionLink>
    );
}
