import React, {Component} from 'react';
import _ from "../_";
import {ApiService} from "../_/ApiService";
import {IonIcon, IonImg} from "@ionic/react";
import {personCircle, personCircleOutline} from "ionicons/icons";
import styles from './Common.module.scss';

type Props = {
    圖片組?: string | null;

    //#region Option: Generate DefaultImage (Pick-One)
    名稱?: string;
    DefaultImage?: string;
    DefaultContent?: string;
    //#endregion

    testMode?: boolean,

    emitNullIfEmpty?: boolean
};

export default function Ln_TokenPictureSetCoverImg(props: Props) {
    const param_圖片組 = props['圖片組'] || '';
    const param_名稱 = props['名稱'];
    const param_DefaultImage = props['DefaultImage'];
    const param_DefaultContent = props['DefaultContent'];
    const param_TestMode = props['testMode'] || false;

    let firstImgId = _._b.getCoverSafeList(param_圖片組)[0];

    if (param_TestMode) {
        return <React.Fragment>
            <IonImg src={"https://via.placeholder.com/90x90.png?text=NoImage"} alt={param_名稱}/>
        </React.Fragment>;
    }

    if (firstImgId == null) {
        if (param_DefaultImage) {
            return <React.Fragment>
                <img loading="lazy" src={param_DefaultImage}/>
            </React.Fragment>;
        }
        if (param_DefaultContent) {
            return param_DefaultContent;
        } else {
            return <React.Fragment>
                {/*<IonImg src={"https://via.placeholder.com/700x700.png?text=NoImage"} alt={param_名稱}/>*/}
                {/*<IonImg src={ personCircle } alt={param_名稱} className={styles.defaultAvatarBgColor}/>*/}
                <IonIcon icon={ personCircle }  className={styles.defaultAvatarBgColor}/>
                {/*<img src={`https://ui-avatars.com/api/?name=${encodeURIComponent(param_名稱 || '')}`}/>*/}
            </React.Fragment>;
        }
    } else {
        if (firstImgId.indexOf('data:') !== -1) {
            return <React.Fragment>
                <img loading="lazy" src={firstImgId.replaceAll('_#comma|_', ',')}/>
            </React.Fragment>;
        }
        return <React.Fragment>
            <img loading="lazy"
                 src={ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')}
                 srcSet={`${ApiService.urlSmartImageMapping("~/" + firstImgId, 'crop', 700, '')} 700w, ${ApiService.urlSmartImageMapping("~/" + firstImgId)} 1200w`}
            />
        </React.Fragment>;
    }
}
