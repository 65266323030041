import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter, RefresherEventDetail, IonSelect, IonSelectOption, IonAvatar, IonLabel} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useCallback, useContext, useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import {Pg_B_1_Store, push_pg_B_1_emailverify, push_pg_B_1_sendemailverify} from "../store/Pg_B_Store";
import {Pg_C_4_Store, push_pg_C_1, push_pg_C_4_phoneverify, push_pg_C_4_sendphoneverify} from "../store/Pg_C_Store";
import {handleFetchCommonErrors, navToPg_A_1} from "./Utils";
import {fetch_pg_3_1, fetch_pg_3_1__Write, Pg_3_1__Write_Store, push_pg_3_11__Comment, push_pg_3_1__Write} from "../store/Pg_3_Store";
import {fetch_pg_2_3_entrust, Pg_2_3_Entrust_Store} from "../store/Pg_2_Store";
import {useStoreState} from "pullstate";
import {getPageData} from "../store/Selectors";
import {Pg_3_1_Store} from "../store/PgStore";
import {useParams} from "react-router";
import {fetch_pg_1_6, Pg_1_6_Store} from "../store/Pg_1_Store";
import styles_Pg_C_1 from "./Pg_C_1.module.scss";
import {push_pg_0_3} from "../store/Pg_0_Store";
import styles_Modal_3_1 from "./Modal_3_1_WriteArticle.module.scss";
import Ln_TokenPictureSetCoverImg from "./Ln_TokenPictureSetCoverImg";
import {v4 as uuidv4} from "uuid";
import MyUploadProgress from "./MyUploadProgress";
import MyUploadFail from "./MyUploadFail";
import ChunkedUploady, {UploadyContext, useBatchFinishListener, useChunkStartListener} from "@rpldy/chunked-uploady";
import {useBatchAddListener, useRequestPreSend} from "@rpldy/uploady";
import Button from "@mui/material/Button";
import AddCircleOutlineSharp from "@mui/icons-material/AddCircleOutlineSharp";


export const useLoginFields = () => {
    return [
        {
            id: "commentText",
            label: "留言內文",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入留言內文",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        }

    ];
}

const customSelectContainerOptions = {
    header: '貼文相關物品',
    subHeader: '',
    message: '請選擇一項',
    translucent: false,
};


const MAX_FILE_SIZE = 99000000;
const MyUploadButton = ({setUploadedFile}) => {
    const uploady = useContext(UploadyContext);
    const [fileId, set_fileId] = useState("");
    useRequestPreSend(({items}) => {
        const _fileID = uuidv4() + "." + items[0].file.name.split(".").pop();
        set_fileId(_fileID);
        return {
            options: {
                destination: {
                    params: {
                        fileId: fileId
                    }
                }
            }
        };
    });
    useChunkStartListener(({chunk, chunkItem}) => {
        return {
            sendOptions: {
                method: "POST",
                paramName: "data",
                params: {
                    // chunkId: chunk?.index,
                    fileSize: chunkItem?.file?.size
                }
            }
        };
    });
    useBatchAddListener((batch) => {
        let result;
        if (batch?.items[0]?.file?.size > MAX_FILE_SIZE) {
            result = false; //false will cancel.
        }
        return result;
    });
    useBatchFinishListener((batch) => {
        if (batch?.items[0]?.state === "finished") {
            // saveUploadedDocumentToProject({fileName: batch?.items[0]?.file.name,fileId: fileID,});

            let fileId_2 = JSON.parse(batch.items[0].uploadResponse.results[0].data).files[0].id;
            //console.log(fileId);

            setUploadedFile(fileId_2);
        }
    });
    const handleUpload = useCallback(() => {
        uploady.showFileUpload();
    }, [uploady]);
    return (
        <Button onClick={handleUpload}>
            <AddCircleOutlineSharp/>
            <span>&nbsp;&nbsp;上傳圖片</span>
        </Button>
    );
};

const RenderForm = ({router, List_TokenEnw, onResult = null, reload = null, dismiss = null}: {
    router: any,
    List_TokenEnw: any,
    reload: any,
    dismiss: any,
    onResult: any,
}) => {

    const [uploadedFile, setUploadedFile] = useState<string | null>(null);

    //#region +Event
    const fields = [
        {
            id: "commentText",
            label: "留言內文",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入留言內文",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        },
        {
            id: "TokenEnwId",
            label: "物品唯一碼",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入物品唯一碼",
                },
                state: useFormInput("", (val, oldVal) => {
                })
            }
        },
        // {
        //     id: "File",
        //     label: "照片",
        //     required: true,
        //     input: {
        //         props: {
        //             type: "file",
        //             placeholder: "請張貼一個照片",
        //         },
        //         state: useFormInput("", (val, oldVal) => {
        //         })
        //     }
        // },
    ];

    let keyedFields = indexBy(fields, 'id');

    //#endregion

    const [errors, setErrors] = useState(false);

    const handleScan = result => {
        if (result) {
            //setQRData(result);
            //play();
            //handleSuccess(result);
            alert(`代碼: ${result.text}`);
            keyedFields.code.input.state.reset(result.text);
            //onResult(result);
        }
    }

    const push_writeArticle = async () => {
        debugger;
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            const commentText = fields && (keyedFields.commentText as any).input.state.value;
            const TokenEnwId = fields && (keyedFields.TokenEnwId as any).input.state.value;

            /*
                p1: string, //param_photoSet
    p2: string, //param_contractId
    p3: string, //param_content
    p4: string, //param_TokenEnwId
             */

            if (uploadedFile == null) {
                alert('請上傳一張照片');
                return;
            }

            try {
                if (await push_pg_3_1__Write({
                    p1: uploadedFile,
                    p2: '',
                    p3: commentText,
                    p4: TokenEnwId,
                })) {
                    //alert('謝謝註冊，現在請輸入剛註冊的帳密登入');
                    //navToPg_A_1(router);
                    //dismiss();
                    //onResult(true);
                    alert('張貼成功')

                    if (dismiss) {
                        dismiss();
                    }
                    if (onResult) {
                        onResult(true);
                    }

                }
            } catch (err) {
            }
        }
    }

    return <>
        <IonRow>
            <IonCol className={'flex'}>
                {/*<CustomField key={keyedFields.File.id} field={keyedFields.File} errors={errors} noLabel={true}/>*/}
                <ChunkedUploady
                    destination={{url: "/MemberUploadHandler.ashx"}}
                    chunkSize={5242880}
                    multiple={false}
                >
                  <div className={styles_Modal_3_1.h50px}>
                    {Ln_TokenPictureSetCoverImg({
                        圖片組: uploadedFile,
                        名稱: 'cover',
                    })}
                  </div>
                    <div>
                        <MyUploadButton setUploadedFile={setUploadedFile}/>
                        <MyUploadProgress/>
                        <MyUploadFail/>
                    </div>
                </ChunkedUploady>
            </IonCol>
        </IonRow>
        <IonRow>
            <IonCol>
                <CustomField mainZone={<>
                    <IonSelect value={keyedFields.TokenEnwId.input.state.value} interfaceOptions={customSelectContainerOptions} interface="action-sheet" placeholder="物品唯一碼" onIonChange={(ev) => {
                        keyedFields.TokenEnwId.input.state.reset_WithEndCallbacks(ev.detail.value);
                    }}
                               multiple={false} className={styles_Pg_C_1.select}>
                        {List_TokenEnw.map((v) => {
                            return <IonSelectOption key={v.TokenEnwId} value={v.TokenEnwId}>{v._Token._合約.Name} {v._Token.名稱} #{v.Edition}</IonSelectOption>
                        })}
                    </IonSelect>
                </>} field={keyedFields.TokenEnwId} errors={errors} noLabel={false}/>
            </IonCol>
        </IonRow>

        <IonRow>
            <IonCol>
                <CustomField key={keyedFields.commentText.id} field={keyedFields.commentText} errors={errors} noLabel={false} textType="textarea"/>
            </IonCol>
        </IonRow>

        <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
            <IonCol>
                <IonButton fill={"solid"} onClick={(e) => {
                    //_push_pg_4_2__AddCouponBookmark(Item_合約獎勵.合約獎勵Id);
                    // alert('代碼為空，無法兌換')

                    e.preventDefault();
                    e.stopPropagation();

                    push_writeArticle();

                    return false;
                }}>
                    送出
                </IonButton>
            </IonCol>
        </IonRow>
    </>

}


export const Modal_3_1_WriteArticle = ({dismiss, onResult, reloadParent = null}) => {

    const router = useIonRouter();

    // useEffect(() => {
    //     setSideMenu({options: sideMenuOptions, side: "start", pageName: pageId});
    // }, []);

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion


    //#region Data Loading
    //const {tokenId}: any = useParams();

    useEffect(() => {
        (async () => {
            let errorObj = await fetch_pg_3_1__Write({
                //p1: tokenId,
            });
            handleFetchCommonErrors(router, errorObj);
        })();
    }, []);

    let reload = async (event: CustomEvent<RefresherEventDetail>) => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        let errorObj = await fetch_pg_3_1__Write({
            //p1: tokenId,
        });
        handleFetchCommonErrors(router, errorObj);
        event?.detail?.complete();
    }

    const pgData = useStoreState(Pg_3_1__Write_Store, getPageData);

    const hasLoaded = pgData?.Root ?? false;
    const List_TokenEnw = hasLoaded ? pgData.Root.List_TokenEnw : undefined;

    //#endregion


    const fields = useLoginFields();
    let keyedFields = indexBy(fields, 'id');


    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>新增貼文</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol>

                    </IonCol>
                </IonRow>

                {hasLoaded &&
                    <RenderForm router={router} List_TokenEnw={List_TokenEnw} onResult={onResult} reload={reload} dismiss={dismiss}/>
                }

            </IonGrid>
        </IonContent>
    </>;
};
