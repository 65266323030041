import {IonGrid, IonRow, IonCol, IonModal, IonButtons, IonButton, IonIcon, IonContent, IonHeader, IonToolbar, IonTitle, useIonRouter} from "@ionic/react";
import {checkmarkOutline, chevronBack, scan} from "ionicons/icons";
import {QrReader} from "react-qr-reader";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import {OnResultFunction} from "react-qr-reader/src/types";
import CustomField from "../components/CustomField";
import {useFormInput, validateForm} from "../_/useForms";
import {useEffect, useState} from "react";
import indexBy from "underscore/modules/indexBy";
import styles_Modal_3_11 from './Modal_3_11.module.scss';
import {Pg_B_1_Store} from "../store/Pg_B_Store";
import {Pg_C_4_Store, push_pg_C_1} from "../store/Pg_C_Store";
import {navToPg_A_1} from "./Utils";
import {push_pg_3_11__Comment} from "../store/Pg_3_Store";


export const useLoginFields = () => {
    return [
        {
            id: "commentText",
            label: "留言內文",
            required: true,
            input: {
                props: {
                    type: "text",
                    placeholder: "請輸入留言內文",
                    autoGrow: false,
                },
                state: useFormInput("")
            }
        }

    ];
}

export const Modal_3_11_WriteComment = ({文章Id, dismiss, onResult}) => {

    const router = useIonRouter();

    //#region Back button
    useEffect(() => {
        let callback: any = (ev) => {
            // ev.detail.register(10, () => {
            //     console.log('Handler was called!');
            // });

            dismiss();
        };

        document.addEventListener('ionBackButton', callback);
        // Remove listener on unmount
        return () => document.removeEventListener("ionBackButton", callback);
    }, []);
    //#endregion


    const fields = useLoginFields();
    let keyedFields = indexBy(fields, 'id');


    const [errors, setErrors] = useState(false);

    const handleScan = result => {
        if (result) {
            //setQRData(result);
            //play();
            //handleSuccess(result);
            alert(`代碼: ${result.text}`);
            keyedFields.code.input.state.reset(result.text);
            //onResult(result);
        }
    }

    const push_register = async () => {
        debugger;
        const errors = validateForm(fields);
        setErrors(errors);

        if (!errors.length) {
            const commentText = fields && (keyedFields.commentText as any).input.state.value;

            try {
                if (await push_pg_3_11__Comment({
                    p1: 文章Id,
                    p2: commentText,
                })) {
                    //alert('謝謝註冊，現在請輸入剛註冊的帳密登入');
                    //navToPg_A_1(router);
                    dismiss();
                    onResult(true);
                }
            } catch (err) {
            }
        }
    }

    return <>
        <IonHeader>
            <IonToolbar>
                <IonTitle>我要留言</IonTitle>
                <IonButtons slot="start">
                    <IonButton onClick={dismiss}>
                        <IonIcon size="large" icon={chevronBack} style={{marginLeft: "-0.7rem"}}/>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
        </IonHeader>

        <IonContent fullscreen>
            <IonGrid>
                <IonRow className="ion-justify-content-center">
                    <IonCol>

                    </IonCol>
                </IonRow>

                {/*<IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">*/}
                {/*    <IonCol>*/}
                {/*        <IonButton fill={"solid"}>*/}
                {/*            兌換*/}
                {/*        </IonButton>*/}
                {/*    </IonCol>*/}
                {/*</IonRow>*/}

                <IonRow>
                    <IonCol>
                        <CustomField key={keyedFields.commentText.id} field={keyedFields.commentText} errors={errors} noLabel={false} textType="textarea"/>
                    </IonCol>
                </IonRow>

                <IonRow className="ion-justify-content-center ion-text-center ion-margin-top ">
                    <IonCol>
                        <IonButton fill={"solid"} onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            //_push_pg_4_2__AddCouponBookmark(Item_合約獎勵.合約獎勵Id);
                            // alert('代碼為空，無法兌換')
                            push_register();

                            return false;
                        }}>
                            送出
                        </IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    </>;
};
