import {Store, useStoreState} from "pullstate";
import _ from "../../src/_/index";
import {Pg_C_1_Store} from "./Pg_C_Store";
import {Pg_1_12_Store} from "./Pg_1_Store";
import {UserCouponBookmarkStore, UserPostBookmarkStore, UserPostCommentFavorStore, UserPostFavorStore} from "./Common_Store";
import {getPageData} from "./Selectors";
import indexBy from "underscore/modules/indexBy";

export const Pg_3_1_Store = new Store({});

export const Pg_3_1__Write_Store = new Store({});

export const Pg_3_11_Store = new Store({});


let fetchOption = (postParams) => {

    return {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(postParams) // body data type must match "Content-Type" header
    } as any;
};

export const fetch_pg_3_1 = async (postParams: {
    p1: string | null  //contractId
    p2: string | null //userId
    p3: boolean | null //isBookmark
    p4: boolean | null //isMy
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_3_1`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_3_1_Store.update(s => {
            return Object.assign(s, payload)
        });

        if (payload.Root.Attach_List_文章_List_會員_文章__讚) {
            let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__讚, '文章Id');

            UserPostFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
        if (payload.Root.Attach_List_文章_List_會員_文章__關注) {
            let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__關注, '文章Id');

            UserPostBookmarkStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const fetch_pg_3_11 = async (postParams: {
    p1: string //postId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_3_11`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_3_11_Store.update(s => {
            return Object.assign(s, payload)
        });
        if (payload.Root.Attach_List_文章_List_會員_文章__讚) {
            let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__讚, '文章Id');

            UserPostFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
        if (payload.Root.Attach_List_文章_List_會員_文章__關注) {
            let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__關注, '文章Id');

            UserPostBookmarkStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
        if (payload.Root.Attach_List_留言_List_會員_留言__讚) {
            let keyed = indexBy(payload.Root.Attach_List_留言_List_會員_留言__讚, '留言Id');

            UserPostCommentFavorStore.update(s => {
                return Object.assign(s, keyed)
            });
        }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}

export const fetch_pg_3_1__Write = async (postParams: {
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/pg_3_1/write`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        Pg_3_1__Write_Store.update(s => {
            return Object.assign(s, payload)
        });

        // if (payload.Root.Attach_List_文章_List_會員_文章__讚) {
        //     let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__讚, '文章Id');
        //
        //     UserPostFavorStore.update(s => {
        //         return Object.assign(s, keyed)
        //     });
        // }
        // if (payload.Root.Attach_List_文章_List_會員_文章__關注) {
        //     let keyed = indexBy(payload.Root.Attach_List_文章_List_會員_文章__關注, '文章Id');
        //
        //     UserPostBookmarkStore.update(s => {
        //         return Object.assign(s, keyed)
        //     });
        // }
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    // await data.filter((quote, index) => {
    //     quote.id = (Date.now() + index);
    //     quote.image = `https://source.unsplash.com/random/1200x400?sig=${ quote.id }`;
    // });
}


export const push_pg_3_1__Write = async (postParams: {
    p1: string, //param_photoSet
    p2: string, //param_contractId
    p3: string, //param_content
    p4: string, //param_TokenEnwId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_1/write`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__Comment = async (postParams: {
    p1: string,
    p2: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/comment`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });

        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__AddCommentFavor = async (postParams: {
    p1: string, //commentId
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/addcommentfavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostCommentFavorStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__RemoveCommentFavor = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/removecommentfavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostCommentFavorStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__AddArticleFavor = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/addarticlefavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostFavorStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__RemoveArticleFavor = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/removearticlefavor`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostFavorStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__AddArticleBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/addarticlebookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostBookmarkStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

export const push_pg_3_11__RemoveArticleBookmark = async (postParams: {
    p1: string,
}) => {
    const response = await fetch(`${_._s.API_ROOT_URL}/api/1/_pg_3_11/removearticlebookmark`, fetchOption(postParams));
    const data = await response.json();

    let error = data.error || null;
    let result = error == null;

    if (result) { // SUCEESS
        let payload = data.payload;
        _._d.warn(payload);
        // Pg_3_11_Store.update(s => {
        //     let o: any = s;
        //     Object.assign(o, payload);
        //     o._pg_3_1 = payload;
        //     return o;
        // });
        UserPostBookmarkStore.update(s => {
            return Object.assign(s, payload)
        });
        return result;
    } else {
        if (error.code === 2) {
            return error;
        }
        alert(error.message);
        throw (error.message);
    }

    return false;
}

