import {Store} from "pullstate";
import _ from "../../src/_/index";
import {createSelector} from 'reselect';
import {Storage} from '@ionic/storage';

export const AuthStore = new Store({});
export const UserCouponBookmarkStore = new Store({});
export const UserPostFavorStore = new Store({});
export const UserPostCommentFavorStore = new Store({});
export const UserPostBookmarkStore = new Store({});
export const UserTokenEntrustStore = new Store({});
export const UserTokenFavorStore = new Store({});



// export const getCartCount = createSelector(getState, state => state.cart.length);



//#region AuthStore
const deviceLongStorage = new Storage();
let hasStoreInit = false;

const getDeviceLongStorage = async () => {
    if (!hasStoreInit) {
        await deviceLongStorage.create();
    }
}


export const initAuth = async () => {
    await getDeviceLongStorage();
    let authBlock:any = await deviceLongStorage.get('Auth');

    AuthStore.update(s => {
        let _s: any = s;

        if (authBlock && authBlock.signBy) {
            Object.assign(_s, authBlock);
            Object.assign(_s, {signBy: 'deviceLongStorage'})
        }
        //o._pg_A_1 = payload;
        return _s;
    });
}

export const saveOfflineAuth = async () => {
    const currentAuthStore = AuthStore.getRawState();
    await deviceLongStorage.set('Auth', currentAuthStore);
}
export const destoryOfflineAuth = async () => {
    const currentAuthStore = AuthStore.getRawState();
    await deviceLongStorage.set('Auth', null);
}

export const setAuthSignBy = async (val) => {
    const currentAuthStore = AuthStore.getRawState();

    AuthStore.update(s => {
        let _s = s as any;
        _s.signBy = val;
    });
}
export const Logout = async () => {
    await destoryOfflineAuth();

    //fix race condition by postpone UI
    window.setTimeout(()=> {
        setAuthSignBy(null);
    }, 500);
    /*
        signBy
        AuthData = Item_會員,
        SessionId = Session.SessionID,
     */
    return true;
}
//#endregion
